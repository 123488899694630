import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FooterComponent } from './footer/footer.component';

import { MessageService } from './message.service';

import { AgmCoreModule } from '@agm/core';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    ContactComponent,
    ScheduleComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase, 'beccamcconnell-v2'),
    AngularFirestoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCey_6WDnzKPwuNsWxT1DV-flfyJjW10rE'
    }),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    MessageService,
    {provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6Lcp6mQUAAAAANLL8E5ojQ1HCN5fEL3yxczxePWQ'
    } as RecaptchaSettings}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
