import { Component, OnInit } from '@angular/core';
import * as Typed from 'typed.js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    // Call ultimate-bg plugin
    $('.bg-video').c47bg({
      type: 'youtube',
      container: 'div',
      source: 'QuylOZXeCMI'
      // type: 'self-hosted',
      // container: 'div',
      // source: {
      //   mp4: '../../assets/bg-videos/5838320-sd_x264.mp4'
      // },
      // crop: false
    });

    // Call jquery plugin scrollIt
    jQuery(function(){
      jQuery.scrollIt({
        easing: 'swing',      // the easing function for animation
        scrollTime: 900,       // how long (in ms) the animation takes
        activeClass: 'active', // class given to the active nav element
        onPageChange: null,    // function(pageIndex) that is called when page is changed
        topOffset: -76         // was -63
      });
    });

    var wind = $(window);

    /* navbar scrolling background and change logo
    -------------------------------------------------------*/
    wind.on("scroll",function () {

      var bodyScroll = wind.scrollTop(),
          navbar = $(".navbar");

      if(bodyScroll > 150){
        navbar.addClass("nav-scroll navbar-light");
        navbar.removeClass("navbar-dark");
      }else{
        navbar.removeClass("nav-scroll navbar-light");
        navbar.addClass("navbar-dark");
      }
    });

    // typed.js
    const options = {
      strings: [
        'Does it feel like you and your mate are worlds apart?',
        'Do you wonder if it is even possible to regain closeness again?',
        'Does the thought of even trying seem too daunting?',
        'If you answered "yes" to these questions, I can help.',
        'I help Dripping Springs and Austin couples recreate closeness and intimacy...',
        '...and today might be the first day towards the start of your second relationship...',
        '...with each other.'  
      ],
      typeSpeed: 40,
      loop: true,
      startDelay: 1000,
      backDelay: 3000
    }

    let typed = new Typed('.home .caption h2 span', options);

    $(document).ready(function(){
      $(".owl-carousel").owlCarousel({
        items: 3,
        loop: true,
        autoplay: true,
        autoplayTimeout: 10000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
          0: {
            items:1,
            nav:false
          },
          768: {
            items:2,
            nav:false
          },
          992: {
            items:3,
            nav:false
          }
        }
      });
    });

  }

}
