import { Component, OnInit, ViewChild } from '@angular/core';

import { MessageService } from '../message.service';

import { Message } from '../models/Message';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  message: Message = {
    name: '',
    email: '',
    subject: '',
    messageContent: ''
  }

  public formModel: FormModel = {};

  @ViewChild('contactForm') form: any;

  // map data
  title: string = 'Becca McConnell';
  lat: number = 30.184301;
  lng: number = -97.817890;

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit() {

  }

  onSubmit({value, valid}: {value: Message, valid: boolean}) {
    // Add new message
    this.messageService.newMessage(value);
    // Show message

    document.querySelector('.alert.alert-success').classList.add('d-block');
    setTimeout(function(){
      document.querySelector('.alert.alert-success').classList.remove('d-block');
    }, 5000);

  }

}
