import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';

import { Message } from './models/Message';

@Injectable()
export class MessageService {
  messagesCollection: AngularFirestoreCollection<Message>;
  messageDoc: AngularFirestoreDocument<Message>;
  messages: Observable<Message[]>;
  message: Observable<Message>;

  constructor(private afs: AngularFirestore) {
    this.messagesCollection = this.afs.collection('messages', ref => ref.orderBy('name', 'asc'));
  }
  newMessage(message: Message) {
    this.messagesCollection.add(message);
  }
}
