import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  services =  [
    {
      "name": "Couples & Marriage Counseling",
      "img": "assets/images/couples-marriage-counseling.png",
      "description": "Counseling can provide couples with vital communication and conflict resolution skills to weather life's difficult times and wind up closer than before."
    },
    {
      "name": "Premarital Counseling",
      "img": "assets/images/premarital-counseling.png",
      "description": "Strengthen your future marriage by communicating about crucial issues such as finances, sex, conflict resolution and role expectations before the big day."
    },
    {
      "name": "Dating Coaching",
      "img": "assets/images/dating-coaching.png",
      "description": "Confused by how to navigate today’s world of dating?  Learn how to operate during the crucial stage of dates 1-10 while quickly weeding out those who are incompatible."
    },
    {
      "name": "Moms",
      "img": "assets/images/moms.png",
      "description": "Are you a woman who is tired of running on empty?  You are inspiring.  You are enough.  Learn how to truly feel and believe those statements."
    },
    {
      "name": "Anxiety & Depression",
      "img": "assets/images/anxiety.png",
      "description": "It feels hopeless when it seems nobody cares or understands. Through relationship woes, grieving or feelings of depression, counseling can provide the support you need to get through."
    },
    {
      "name": "LPC-Associate Supervision",
      "img": "assets/images/supervision.png",
      "description": "Let’s get on with the business of making you not just a phenomenal counselor, but a successful one too."
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
