import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  testimonies = [
    {
      quote: "After my first session I began to see a light at the end of the tunnel. I knew I could eventually find myself and be happy again.",
      icon: 'fa-cloud',
      title: 'Individual Client',
      subtitle: 'Anxiety & Depression'
    },
    {
      quote: "She keeps track of everything that I've discussed in prior sessions and always remembers little things that I tell her. I feel like I'm talking to an open, honest friend that is looking out for me and building me up.",
      icon: 'fa-cloud',
      title: 'Individual Client',
      subtitle: 'Anxiety & Depression'
    },
    {
      quote: "I left each talk feeling reinvigorated and prepared to better my life and relationships--a few people even told me I was noticeably more lighthearted after meeting with her!",
      icon: 'fa-heart',
      title: 'Individual Client',
      subtitle: 'Dating Coaching'
    },
    {
      quote: "Becca helped save my marriage during a particularly dark time in my life. I am forever grateful.",
      icon: 'fa-couch',
      title: 'Couple',
      subtitle: 'Marriage Counseling'
    },
    {
      quote: "Becca is attentive, easy to talk to, and a very active listener. She provides thoughtful questions and is good about making sure we talk about things in the session we want to talk about.",
      icon: 'fa-couch',
      title: 'Couple',
      subtitle: 'Marriage Counseling'
    },
    {
      quote: "I definitely always felt better and more level headed after leaving a session.",
      icon: 'fa-user',
      title: 'Individual Client',
      subtitle: 'Counseling for Moms'
    },
    {
      quote: "Becca not only helped us with the specific issues we brought into therapy, but she also provided us with additional tools to manage and resolve future conflicts.",
      icon: 'fa-couch',
      title: 'Couple',
      subtitle: 'Premarital Counseling'
    },
    {
      quote: "She was instrumental in guiding our marriage through a very difficult time and she did it with empathy and - somehow! - a sense of humor.",
      icon: 'fa-couch',
      title: 'Couple',
      subtitle: 'Marriage Counseling'
    },
    {
      quote: "I felt almost hopeless when I started my sessions with Becca, and now I feel confident and empowered in my life.",
      icon: 'fa-cloud',
      title: 'Individual Client',
      subtitle: 'Anxiety & Depression'
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
