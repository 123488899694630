import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private mobile = true;

  private screenHeight: number;
  private screenWidth: number;
  
  constructor() { this.onResize(); }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    console.log(this.screenWidth);
    if (this.screenWidth > 575) {
      this.mobile = false;
    }
  }

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.mobile = (window.innerWidth < 576) ? true : false;
  }

}
